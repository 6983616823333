<template>
  <WeContainer header="true" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:allowSortIndex="false"
      v-bind:data="equipment.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-bind:ajax="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-on:make-request="updateEquipmentList"
      ref="equipmentTable"
    ></WeTable>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      loading: false,
      actions: [
        {
          text: "Yeni Ekipman",
          path: "equipments/new",
        },
      ],
      tableActions: [
        // {
        //   icon: "fas fa-trash",
        //   class: "btn-outline-danger",
        //   childrenText: "Ürün",
        //   action: "children",
        // },
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary ml-2",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "manufacturer", th: "Ekipman Üreticisi", type: "string" },
        { name: "name", th: "Ekipman Adı", type: "string" },
        { name: "sku_no", th: "Ekipman Kodu", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("equipment", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("equipments/detail/" + row.id);
    },
    onDelete(row, index) {
      let message =
        row.name + " adlı ekipmanı silmek istediğinize emin misiniz ?";
      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$swal({
                  title: "Ekipman Başarıyla Silindi",
                  icon: "success",
                  confirmButtonText: "Tamam",
                });
              } else {
                this.$swal({
                  title: "Ekipman Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onSwitch(data) {
      this.isActive(data);
    },
    updateEquipmentList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState(["equipment"]),
  },
};
</script>
